import { useCallback, useEffect } from 'react';
import configApiSlice from '@State/config';
import { ApiTags, LSKeys } from '@Src/constants';
import { useDispatch } from 'react-redux';

const { useGetIsManualOfflineQuery } = configApiSlice;

const useHandleOffline = () => {
  const { data: isManuallyOffline } = useGetIsManualOfflineQuery(undefined);
  const dispatch = useDispatch();

  const invalidateOffline = useCallback(() => {
    dispatch(configApiSlice.util.invalidateTags([ApiTags.networkOfflineMode]));
  }, [dispatch]);

  useEffect(() => {
    if (!navigator.onLine) {
      localStorage.setItem(LSKeys.networkOffline, '1');
      invalidateOffline();
    }
  }, [invalidateOffline]);

  useEffect(() => {
    const handleOnline = () => {
      localStorage.removeItem(LSKeys.networkOffline);
      invalidateOffline();
    };

    const handleOffline = () => {
      localStorage.setItem(LSKeys.networkOffline, '1');
      invalidateOffline();
    };

    if (isManuallyOffline) {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    } else {
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [invalidateOffline, isManuallyOffline]);
};

export default useHandleOffline;
