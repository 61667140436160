enum ApiTags {
  albums = 'ALBUMS',
  login = 'LOGIN',
  manualOfflineMode = 'MANUAL_OFFLINE_MODE',
  me = 'ME',
  networkOfflineMode = 'NETWORK_OFFLINE_MODE'
}

const isOffline = () => !!localStorage.getItem('manual-offline') || !!localStorage.getItem('network-offline');

enum LSKeys {
  manualOffline = 'manual-offline',
  me = 'me',
  networkOffline = 'network-offline',
}

enum Paths {
  home = '/',
  login = '/login'
}

enum FeatureFlags {
  testFlag = 'test-flag',
}

export {
  ApiTags,
  Paths,
  FeatureFlags,
  isOffline,
  LSKeys,
};
