import { serviceApi } from '@Store/api';
import { ApiTags, isOffline, LSKeys } from '@Src/constants';
import {
  addData, getData, Stores,
} from '@Helpers/db';

type Album = {
  id: string;
  thumbnailUrl: string;
  title: string;
}

type ApiResponse = Album[];

export const configApiSlice = serviceApi.injectEndpoints({
  endpoints: (builder) => ({

    getAlbums: builder.query<Album[], {limit: number}>({
      queryFn: async ({ limit }, _, __, fetchWithBQ) => {
        if (isOffline()) {
          const data = await getData<Album>(Stores.albums);
          return { data };
        }

        const res = await fetchWithBQ({
          baseURL: 'https://jsonplaceholder.typicode.com',
          url: `/photos?_limit=${limit}`,
          method: 'get',
          skipCookieCheck: true,
          withCredentials: false,
        });

        const albums = (res.data.items as ApiResponse).map(({ id, title, thumbnailUrl }) => ({
          id,
          title,
          thumbnailUrl,
        }));

        await addData<Album[]>(Stores.albums, albums);

        return {
          data: albums,
        };
      },
      providesTags: [ApiTags.albums],
    }),

    getIsManualOffline: builder.query({
      queryFn: () => ({ data: !!localStorage.getItem(LSKeys.manualOffline) }),
      providesTags: [ApiTags.manualOfflineMode],
    }),

    getIsNetworkOffline: builder.query({
      queryFn: () => ({ data: !!localStorage.getItem(LSKeys.networkOffline) }),
      providesTags: [ApiTags.networkOfflineMode],
    }),

    toggleOffline: builder.mutation({
      queryFn: () => {
        try {
          if (localStorage.getItem(LSKeys.manualOffline)) {
            localStorage.removeItem(LSKeys.manualOffline);
            return { data: undefined };
          }

          localStorage.setItem(LSKeys.manualOffline, '1');
          return { data: undefined };
        } catch (err: unknown) {
          console.error(err);

          if (err instanceof Error) {
            return {
              error: {
                status: 500,
                data: err.message,
              },
            };
          }

          return {
            error: {
              status: 500,
              data: 'Something went wrong',
            },
          };
        }
      },
      invalidatesTags: [ApiTags.manualOfflineMode],
    }),
  }),
});
