import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from '@Store/configureStore';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import Router from './router';

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_ENV !== 'PROD',
});

(async () => {
  await growthbook.init({ streaming: true });

  root.render(
    <GrowthBookProvider growthbook={growthbook}>
      <Provider store={store}>
        <Suspense fallback={<div />}>
          <Router />
        </Suspense>
      </Provider>
    </GrowthBookProvider>,
  );
})();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
      console.log('Service Worker registrato con successo');
    }).catch((error) => {
      console.error('Errore nella registrazione del Service Worker:', error);
    });
  });
}
