let request: IDBOpenDBRequest;
let db: IDBDatabase;
let version = 1;

const DB_NAME = 'FGP';
const UNKNOWN_ERROR = 'Unknown error';

export enum Stores {
  albums = 'albums',
}

const initDB = (): Promise<boolean> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME);

  request.onupgradeneeded = () => {
    db = request.result;

    Object.values(Stores).map((store) => {
      if (!db.objectStoreNames.contains(store)) {
        db.createObjectStore(store, { keyPath: 'id' });
      }
      return null;
    });
  };

  request.onsuccess = () => {
    db = request.result;
    version = db.version;
    resolve(true);
  };

  request.onerror = () => {
    resolve(false);
  };
});

const addData = <T>(storeName: Stores, data: T): Promise<T|string|null> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME, version);

  request.onsuccess = () => {
    db = request.result;
    version = db.version;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    if (Array.isArray(data)) {
      data.map((d) => {
        store.add(d);
        return null;
      });
    } else {
      store.add(data);
    }
    resolve(data);
  };

  request.onerror = () => {
    const error = request.error?.message;
    if (error) {
      resolve(error);
    } else {
      resolve(UNKNOWN_ERROR);
    }
  };
});

const getData = <T>(storeName: Stores): Promise<T[]> => new Promise((resolve, reject) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    try {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    } catch (e) {
      reject(Error(`Error: no ${storeName} store found`));
    }
  };
});

const updateData = <T>(storeName: Stores, id: string, data: T): Promise<T|string|null> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME, version);

  request.onsuccess = () => {
    db = request.result;
    version = db.version;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const getRequest = store.get(id);

    getRequest.onsuccess = () => {
      if (getRequest.result) {
        store.put(data);
        resolve(data);
      } else {
        resolve('No item found with the specified ID.');
      }
    };

    getRequest.onerror = () => {
      resolve(`Error fetching item: ${getRequest.error?.message || 'Unknown error'}`);
    };
  };

  request.onerror = () => {
    const error = request.error?.message;
    if (error) {
      resolve(error);
    } else {
      resolve(UNKNOWN_ERROR);
    }
  };
});

const deleteData = (storeName: string, key: string): Promise<boolean> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME, version);

  request.onsuccess = () => {
    db = request.result;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const res = store.delete(key);

    res.onsuccess = () => {
      resolve(true);
    };
    res.onerror = () => {
      resolve(false);
    };
  };
});

const clearStore = (storeName: Stores): Promise<boolean> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME, version);

  request.onsuccess = () => {
    db = request.result;

    // Avvia una transazione in modalità readwrite
    const transaction = db.transaction([storeName], 'readwrite');

    // Ottieni lo store che vuoi svuotare
    const objectStore = transaction.objectStore(storeName);

    // Cancella tutte le righe dallo store
    const clearRequest = objectStore.clear();

    clearRequest.onerror = (event) => {
      console.error('Error:', event.target);
    };

    transaction.onerror = (event) => {
      console.error('Tx Error:', event.target);
    };

    resolve(true);
  };

  request.onerror = () => {
    resolve(false);
  };
});

export {
  initDB,
  addData,
  getData,
  updateData,
  deleteData,
  clearStore,
};
